import v from 'voca';
import { format, isBefore, parseISO } from 'date-fns';
import { useMemo, useState, useEffect, ChangeEvent, useCallback } from 'react';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import {
  Select,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  SelectChangeEvent,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { convertToTitleCase } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import { useGetTestLevelList } from 'src/api/testLevels';

import Iconify from 'src/components/iconify';
import CustomDateRangePicker from 'src/components/custom-date-range-picker/custom-date-range-picker';

import { ITestLevel } from 'src/types/staff/tests';
import { IResultsTableFilters, IResultsTableFilterValue } from 'src/types/results';

type props = {
  filters: IResultsTableFilters;
  onFilters: (name: string, value: IResultsTableFilterValue) => void;
  fileDownloadHandler: () => void;
  isFileLoading: boolean;
};

export default function ResultsToolbar({
  filters,
  onFilters,
  fileDownloadHandler,
  isFileLoading,
}: props) {
  const { data }: any = useGetTestLevelList();
  const dateRangePickerDialog = useBoolean();
  const [error, setError] = useState(false);
  const [dateFilter, setDateFilter] = useState({ startDate: '', endDate: '' });
  const { t } = useTranslate();

  const TEST_LEVELS = useMemo(
    () =>
      data?.map((testLevel: ITestLevel) => ({ label: testLevel.name, value: testLevel.name })) ||
      [],
    [data],
  );
  const handleFilter = useCallback(
    (
      event: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      field: string,
    ) => {
      onFilters(field, event.target.value);
    },
    [onFilters],
  );

  // UseEffect is used for when reset the filters the temporary filters also need to empty
  useEffect(() => {
    if (!filters.startDate) {
      setDateFilter((prev) => ({ ...prev, startDate: '' }));
    }
    if (!filters.endDate) {
      setDateFilter((prev) => ({ ...prev, endDate: '' }));
    }
  }, [filters.startDate, filters.endDate, setDateFilter]);

  // This is the function temporarily update the startDate
  const onChangeStartDate = (value: Date | null) => {
    const formattedDate = value ? format(value, 'yyyy-MM-dd') : '';
    if (dateFilter.endDate && value && isBefore(parseISO(String(dateFilter.endDate)), value)) {
      setError(true);
    } else {
      setError(false);
    }

    setDateFilter({ ...dateFilter, startDate: formattedDate });
  };

  // This is the function temporarily update the endDate
  const onChangeEndDate = (value: Date | null) => {
    const formattedDate = value ? format(value, 'yyyy-MM-dd') : '';
    if (dateFilter.startDate && value && isBefore(value, parseISO(String(dateFilter.startDate)))) {
      setError(true);
    } else {
      setError(false);
    }

    setDateFilter({ ...dateFilter, endDate: formattedDate });
  };

  // When onclose happens, actual filter need to trigger for api
  const onCloseDialog = () => {
    if (!error && dateFilter.startDate) {
      onFilters('startDate', dateFilter.startDate);
    }
    if (!error && dateFilter.endDate) {
      onFilters('endDate', dateFilter.endDate);
    }

    if (error) {
      setDateFilter({ startDate: '', endDate: '' });
      setError(false);
    }

    dateRangePickerDialog.onFalse();
  };

  // Process the combination of startDate and endDate Display
  const getDateRangeValue = useMemo(() => {
    const { startDate, endDate } = dateFilter;

    const formattedStartDate = startDate ? format(new Date(startDate), 'dd-MM-yyyy') : '';
    const formattedEndDate = endDate ? format(new Date(endDate), 'dd-MM-yyyy') : '';

    if (formattedStartDate && formattedEndDate) {
      return `${formattedStartDate} - ${formattedEndDate}`;
    }

    return formattedStartDate || formattedEndDate || '';
  }, [dateFilter]);

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
      }}
    >
      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 287 },
        }}
      >
        {/* <DatePicker
          value={filters.date ? parseISO(String(filters.date)) || null : null}
          label="Date"
          format="yyyy-MM-dd"
          onChange={(newValue) => {
            handleDateFilter(newValue);
          }}
        /> */}

        <TextField
          label={convertToTitleCase(t('results_listing_page.toolbar.date_range'))}
          value={getDateRangeValue}
          onClick={dateRangePickerDialog.onTrue}
          sx={{
            cursor: 'pointer',
            '& .MuiOutlinedInput-input': {
              cursor: 'pointer',
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="uim:calender"
                  sx={{ color: 'text.disabled', cursor: 'pointer' }}
                  width={28}
                  height={28}
                />
              </InputAdornment>
            ),
          }}
        />

        <CustomDateRangePicker
          startDate={dateFilter.startDate ? parseISO(String(dateFilter.startDate)) || null : null}
          endDate={dateFilter.endDate ? parseISO(String(dateFilter.endDate)) || null : null}
          open={dateRangePickerDialog.value}
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
          onClose={onCloseDialog}
          error={error}
        />
      </FormControl>
      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 287 },
        }}
      >
        <InputLabel id="type-multiple-checkbox-label">{t('common.level')}</InputLabel>

        <Select
          labelId="type-multiple-checkbox-label"
          id="type-multiple-checkbox"
          value={filters.level}
          onChange={(e) => handleFilter(e, 'level')}
          input={<OutlinedInput label={t('common.level')} />}
          sx={{ textTransform: 'capitalize' }}
        >
          {TEST_LEVELS.map((option: { label: string; value: string }) => (
            <MenuItem key={option.value} value={option.value}>
              {v.titleCase(option.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <TextField
          fullWidth
          value={filters.search}
          onChange={(e) => handleFilter(e, 'search')}
          placeholder={t('common.search')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          variant="outlined"
          size="small"
          endIcon={<Iconify icon="mingcute:download-3-fill" />}
          loading={isFileLoading}
          sx={{
            color: 'customColors.custom1',
            borderColor: 'customColors.custom1',
            minWidth: 113,
          }}
          onClick={fileDownloadHandler}
        >
          <Typography variant="overline" textTransform="none" whiteSpace="nowrap">
            {t('common.export')} CSV
          </Typography>
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
