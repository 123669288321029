import { useState, ChangeEvent } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Box, Link, Switch, Typography, FormControlLabel } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { convertToTitleCase } from 'src/utils/misc';
import { lightenHexColor } from 'src/utils/lighten-hex-color';

import { useTranslate } from 'src/locales';
import keys from 'src/constants/query-keys';
import { useAuthContext } from 'src/auth/hooks';
import { USER_ROLES } from 'src/constants/user-roles';
import { archiveTest, shareTestToStudent, updateTestPublishStatus } from 'src/api/staff/tests';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import ShareDialog from 'src/components/sharing/share-dialog-new';

import { ITestTableItem } from 'src/types/staff/tests';

// ----------------------------------------------------------------------

type Props = {
  row: ITestTableItem;
  handleTestDuplication: (testData: any) => void;
};

export default function TestsTableRow({ row, handleTestDuplication }: Props) {
  const {
    id,
    name,
    totalQuestions,
    isPublished,
    category,
    lowerThreshold,
    nextTestId,
    prevTestId,
    isPrimary,
    totalResults,
  } = row;

  const queryClient = useQueryClient();

  const { user } = useAuthContext();
  const isAnalyst = user?.role === USER_ROLES.ANALYST;

  const [activeStatus, setActiveStatus] = useState(isPublished);

  const confirm = useBoolean();
  const shareDialog = useBoolean(false);
  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const testIsPrimary =
    isPrimary || (lowerThreshold === null && prevTestId === null && nextTestId === null);

  const handleTestDeletion = async () => {
    confirm.onFalse();

    if (category?.name) {
      enqueueSnackbar(
        t('test_listing_page.test_associated_with_category_delete', { name: category.name }),
        {
          variant: 'error',
        },
      );

      return;
    }
    await archiveTest(id!);
    enqueueSnackbar(t('test_listing_page.test_deleted'));
    queryClient.invalidateQueries({ queryKey: [keys.staff.tests.fetchTests] });
  };

  const isLiveToggling = useBoolean(false);

  const handleLiveToggle = async (e: ChangeEvent<HTMLInputElement>) => {
    const status = e.currentTarget.checked;
    try {
      if (!status && category && category.isPublished) {
        enqueueSnackbar(
          t('test_edit_page.test_cannot_unpublish', { categoryName: category.name }),
          {
            variant: 'error',
          },
        );
        return;
      }

      isLiveToggling.onTrue();
      await updateTestPublishStatus(id, { status });
      setActiveStatus(status);
      isLiveToggling.onFalse();
      queryClient.invalidateQueries({ queryKey: [keys.staff.tests.fetchTests] });
      enqueueSnackbar(
        t('test_listing_page.test_status', { name, status: status ? 'published' : 'unpublished' }),
        {
          variant: 'success',
        },
      );
    } catch (error) {
      enqueueSnackbar(error?.message ? error?.message : JSON.stringify(error || '{}'), {
        variant: 'error',
      });
      isLiveToggling.onFalse();
    }
  };

  const handleShare = async () => {
    shareDialog.onTrue();
  };

  const redirectUrl = `${paths.staff.test.tests.edit(id)}`;

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: '900', lineHeight: '22px', color: '#000000', cursor: 'pointer' }}
          >
            <Link color="inherit" underline="hover" component={RouterLink} href={redirectUrl}>
              {name}
            </Link>
          </Typography>

          <Typography
            variant="body2"
            sx={{ fontWeight: '400', lineHeight: '22px', color: 'grey.500' }}
          >
            {totalResults} {t('common.results')}
          </Typography>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {category && (
            <Label
              variant="soft"
              sx={{
                backgroundColor: lightenHexColor(category.color, 88),
                color: category.color,
                fontWeight: '700',
                fontSize: '12px',
                lineHeight: '20px',
              }}
            >
              {category && category.name}
            </Label>
          )}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {testIsPrimary ? <Iconify icon="material-symbols:check" color="success.main" /> : ''}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Typography variant="body2" sx={{ fontWeight: '400', lineHeight: '22px' }}>
            {totalQuestions}
          </Typography>
        </TableCell>

        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <FormControlLabel
            control={
              <Switch
                checked={activeStatus}
                color="success"
                onChange={handleLiveToggle}
                disabled={isAnalyst}
              />
            }
            label={t('common.live')}
          />
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Preview" placement="top" arrow>
            <IconButton LinkComponent={RouterLink} target="_blank" href={paths.test.answerTest(id)}>
              <Iconify icon="eva:external-link-outline" />
            </IconButton>
          </Tooltip>
        </TableCell>

        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Share" placement="top" arrow>
            <IconButton onClick={handleShare}>
              <Iconify icon="solar:share-bold" />
            </IconButton>
          </Tooltip>
        </TableCell>

        {!isAnalyst && (
          <>
            <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
              <Tooltip title="Duplicate" placement="top" arrow>
                <IconButton
                  LinkComponent={RouterLink}
                  onClick={() => handleTestDuplication(row.id)}
                >
                  <Iconify icon="ph:copy-duotone" />
                </IconButton>
              </Tooltip>
            </TableCell>

            <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
              <Tooltip title="Delete" placement="top" arrow>
                <IconButton onClick={confirm.onTrue} sx={{ color: 'error.dark' }}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            </TableCell>
          </>
        )}
      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={
          <Box display="flex" gap={2} alignItems="center">
            <Iconify icon="solar:trash-bin-trash-bold" />{' '}
            {convertToTitleCase(t('test_listing_page.delete_test'))}
          </Box>
        }
        content={
          <>
            <Typography variant="subtitle1">{t('test_listing_page.sure_delete')}</Typography>
            <Typography variant="body1">
              {t('test_listing_page.test_associated_with_category_delete')}
            </Typography>
          </>
        }
        action={
          <Button variant="contained" color="error" onClick={handleTestDeletion}>
            {t('common.delete')}
          </Button>
        }
      />

      <ShareDialog
        dialog={shareDialog}
        title={t('test_listing_page.share_test')}
        studentLinkToShare={paths.test.answerTest(id, true)}
        onShare={shareTestToStudent}
        addtionalPayloadToShareFn={{ testName: name }}
      />
    </>
  );
}
