import { Children, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import Popover, { PopoverOrigin } from '@mui/material/Popover';
import {
  Box,
  Stack,
  Button,
  Tooltip,
  TableRow,
  TableCell,
  Typography,
  IconButton,
} from '@mui/material';

import { paths } from 'src/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { convertToTitleCase } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import queryKeys from 'src/constants/query-keys';
import { shareResult, deleteResult } from 'src/api/result';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import ShareDialog from 'src/components/sharing/share-dialog-new';
import { getPosition } from 'src/components/custom-popover/utils';

import { ITableColumns } from 'src/types/misc';
import { IResultsModifiedItem } from 'src/types/results';

type Props = {
  row: IResultsModifiedItem;
  headLabel: Partial<ITableColumns>[] | any;
};

export default function ResultsTableRow({ row, headLabel }: Props) {
  const shareDialog = useBoolean(false);
  const confirmStudentDelete = useBoolean();
  const queryClient = useQueryClient();
  const { t } = useTranslate();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { anchorOrigin, transformOrigin } = getPosition('top-right');

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleDeleteResult = async () => {
    try {
      confirmStudentDelete.onFalse();
      await deleteResult(row.id);
      enqueueSnackbar(t('results_listing_page.result_deleted'));
      queryClient.invalidateQueries({ queryKey: [queryKeys.staff.results.fetchAllResults] });
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  const handleShare = async () => {
    shareDialog.onTrue();
  };

  const tableActions = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title={t('common.share')} placement="top" arrow>
        <IconButton onClick={handleShare}>
          <Iconify icon="solar:share-bold" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );

  const tableDeleteAction = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title={t('common.delete')} placement="top" arrow>
        <IconButton onClick={() => confirmStudentDelete.onTrue()} sx={{ color: 'error.dark' }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );

  row?.sectionBreakdown?.sort((a, b) => {
    const nameA = a.name || '';
    const nameB = b.name || '';
    return nameA.localeCompare(nameB);
  });

  return (
    <>
      <TableRow hover>
        {Children.toArray(
          headLabel.map((headCell: any) => {
            if (headCell?.field_mod) {
              return (
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    width: headCell.width,
                    minWidth: headCell.minWidth,
                  }}
                  align={headCell.align || 'left'}
                >
                  {/* @ts-ignore */}
                  {row?.[headCell?.field_mod]}
                </TableCell>
              );
            }
            if (headCell?.id) {
              if (headCell.id === 'name') {
                return (
                  <TableCell
                    sx={{
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                      whiteSpace: 'nowrap',
                      textDecoration: 'none',
                    }}
                    align={headCell.align || 'left'}
                  >
                    <Stack>
                      <Typography variant="subtitle2" fontWeight={900}>
                        {row.studentFullName}
                      </Typography>
                      <Typography variant="caption" sx={{ color: 'customColors.custom1' }}>
                        {row.studentEmail}
                      </Typography>
                    </Stack>
                  </TableCell>
                );
              }

              if (headCell.id === 'date') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {row.date}
                    </Typography>
                  </TableCell>
                );
              }

              if (headCell.id === 'level') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    <Typography variant="subtitle2" fontWeight={900}>
                      {row?.level || ''}
                    </Typography>
                  </TableCell>
                );
              }

              if (headCell.id === 'points') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    <Stack
                      direction="row"
                      spacing={0.5}
                      alignItems="center"
                      justifyContent="center"
                      color={`${row.hasSection && 'customColors.custom1'}`}
                    >
                      <Typography variant="subtitle2" fontWeight={900}>
                        {row.totalPointsScored}/{row.totalPointsPosssible}
                      </Typography>

                      {row.hasSection && (
                        <>
                          <Iconify
                            color="customColors.custom1"
                            icon="fluent:info-24-filled"
                            width={16}
                            height={16}
                            style={{ cursor: 'pointer' }}
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                          />
                          <Popover
                            id="mouse-over-popover"
                            sx={{
                              marginTop: '10px',
                              pointerEvents: 'none',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={anchorOrigin as PopoverOrigin}
                            transformOrigin={transformOrigin as PopoverOrigin}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                          >
                            <Stack
                              direction="column"
                              spacing={0.5}
                              alignItems="center"
                              justifyContent="center"
                              sx={{ width: '100%', gap: 3, p: 2 }}
                            >
                              {row.sectionBreakdown.map(
                                (section, index) =>
                                  section.name !== 'INDEPENDENT QUESTIONS' && (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        gap: 2,
                                      }}
                                      key={index}
                                    >
                                      <Typography variant="subtitle2" fontWeight={900}>
                                        {section.name}
                                      </Typography>
                                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {section.points}
                                      </Typography>
                                    </Box>
                                  ),
                              )}
                              {row.sectionBreakdown.map(
                                (section, index) =>
                                  section.name === 'INDEPENDENT QUESTIONS' && (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        gap: 2,
                                      }}
                                    >
                                      <Typography variant="body2">No section</Typography>

                                      <Typography
                                        variant="body2"
                                        sx={{ color: 'text.secondary' }}
                                        noWrap
                                        key={index}
                                      >
                                        {section.points}
                                      </Typography>
                                    </Box>
                                  ),
                              )}
                            </Stack>
                          </Popover>
                        </>
                      )}
                    </Stack>
                  </TableCell>
                );
              }

              if (headCell.id === 'premises') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    {/* @ts-ignore */}
                    {row?.[headCell?.id].length ? (
                      <Stack direction="row" spacing={2}>
                        <Typography variant="body2" whiteSpace="wrap">
                          {/* @ts-ignore */}
                          {row?.premises.join(', ')}
                        </Typography>
                      </Stack>
                    ) : (
                      ''
                    )}
                  </TableCell>
                );
              }

              if (headCell.id === 'actions') {
                return <>{tableActions}</>;
              }

              return (
                <TableCell
                  sx={{ whiteSpace: 'nowrap', width: headCell.width, minWidth: headCell.minWidth }}
                  align={headCell.align || 'left'}
                >
                  {/* @ts-ignore */}
                  {row?.[headCell?.id]}
                </TableCell>
              );
            }
            return <>{tableDeleteAction}</>;
          }),
        )}
      </TableRow>
      <ConfirmDialog
        open={confirmStudentDelete.value}
        onClose={confirmStudentDelete.onFalse}
        title={
          <Box display="flex" gap={2} alignItems="center">
            <Iconify icon="solar:trash-bin-trash-bold" width={24} height={24} />{' '}
            <Typography variant="h6" fontWeight={900}>
              {convertToTitleCase(t('results_listing_page.delete_result'))}
            </Typography>
          </Box>
        }
        content={
          <Typography variant="subtitle1" fontWeight={400} sx={{ color: 'text.secondary' }}>
            {t('results_listing_page.sure_delete')}
          </Typography>
        }
        action={
          <Button variant="contained" color="error" onClick={handleDeleteResult}>
            {t('common.delete')}
          </Button>
        }
      />
      {/* share dialog box---------------------------------------- */}
      <ShareDialog
        dialog={shareDialog}
        title={t('results_listing_page.share_result')}
        onShare={shareResult}
        enableUserTypeSelection
        addtionalPayloadToShareFn={{
          resultId: row.id,
        }}
        studentLinkToShare={paths.results.resultDetails(row.id)}
      />
    </>
  );
}
