import { isEqual } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { format, isValid, parseISO } from 'date-fns';
import { useMemo, useState, useCallback } from 'react';

import { Card, Table, Container, TableBody, TableContainer } from '@mui/material';

import { useDebounce } from 'src/hooks/use-debounce';

import { csvDownloader } from 'src/utils/file-downloader';
import axiosInstance, { endpoints } from 'src/utils/axios';
import { removeFalsyValuesFromObject } from 'src/utils/misc';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { useGetResultsList } from 'src/api/result';
import { TABLE_DEFAULTS } from 'src/constants/misc';
import { USER_ROLES } from 'src/constants/user-roles';

import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { IListFilters } from 'src/types/misc';
import {
  IPremiseItem,
  IResultsItem,
  IResultsModifiedItem,
  IResultsTableFilters,
  IResultsTableFilterValue,
} from 'src/types/results';

import ResultsToolbar from '../list/results-toolbar';
import ResultsTableRow from '../list/results-table-row';
import ResultsTableFiltersResult from '../list/results-table-filters-result';

const defaultFilters = {
  search: '',
  level: '',
  startDate: '',
  endDate: '',
  searchText: '',
};

const EXCEL_DOWNLOAD_HEADERS = ['Name', 'Date', 'Test', 'Level', 'Points', 'Premise'];

const EXCEL_DATA_KEYS = ['name', 'date', 'test', 'level', 'points', 'premises'];

export default function ResultsListView() {
  const { user } = useAuthContext();
  const isAnalyst = user?.role === USER_ROLES.ANALYST;

  const [filters, setFilters] = useState(defaultFilters);
  const table = useTable({
    defaultRowsPerPage: TABLE_DEFAULTS.ROWS_PER_PAGE,
    defaultOrderBy: 'date',
    defaultOrder: 'desc',
  });
  const settings = useSettingsContext();

  const { t } = useTranslate();

  const TABLE_HEAD = useMemo(
    () => [
      {
        id: 'name',
        label: t('common.name'),
        width: 205,
        align: 'left',
      },
      {
        id: 'date',
        label: t('common.date'),
        width: 143,
        align: 'left',
      },
      {
        id: 'test',
        label: t('common.test'),
        width: 150,
        align: 'left',
      },
      {
        id: 'level',
        label: t('common.level'),
        width: 83,
        align: 'left',
      },
      { id: 'points', label: t('common.points'), width: 83, align: 'left' },
      {
        id: 'premises',
        label: t('common.premises'),
        width: 231,
        align: 'left',
      },
      { id: 'actions', label: t('common.actions'), align: 'left' },
      ...(!isAnalyst ? [{ id: '' }] : []),
    ],
    [t, isAnalyst],
  );

  const [isFileLoading, setIsFileLoading] = useState(false);

  const canReset = !isEqual(defaultFilters, filters);

  const denseHeight = table.dense ? 56 : 56 + 20;

  const handleFilters = useCallback(
    (name: string, value: IResultsTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table],
  );

  const tableFiltersHandler = useCallback(
    ({ fetchAll = false }: { fetchAll?: boolean }) => ({
      ...(fetchAll
        ? { all: true }
        : {
            page: table.page + 1,
            limit: table.rowsPerPage,
          }),
      sortBy: table.orderBy === 'fullName' ? 'name' : table.orderBy,
      sortDirection: table.order,
    }),
    [table],
  );

  const apiFiltersHandler = useCallback(() => {
    const allFilters: Partial<IResultsTableFilters> = { ...filters };
    if (allFilters.search) {
      delete allFilters.search;
    }

    return allFilters;
  }, [filters]);

  const [debouncedSearchText] = useDebounce(filters.search || '', 1500);

  const apiFilters: IListFilters<IResultsTableFilters> = useMemo(() => {
    const allFilters = apiFiltersHandler();
    const tableFilters = tableFiltersHandler({ fetchAll: false });

    return removeFalsyValuesFromObject({
      ...allFilters,
      ...tableFilters,
      search: debouncedSearchText,
    });
  }, [apiFiltersHandler, tableFiltersHandler, debouncedSearchText]);

  const { data }: any = useGetResultsList({ ...apiFilters });

  const {
    results: tableData = [],
    totalCount = 0,
  }: {
    results: IResultsModifiedItem[];
    totalCount: number;
  } = useMemo(() => {
    const modifyData = data?.data.map((result: IResultsItem) => ({
      id: result?.id,
      date: isValid(parseISO(result.createdAt))
        ? format(parseISO(result.createdAt), 'dd-MM-yyyy') || ''
        : '',
      test: result?.test?.name,
      testId: result?.test?.id,
      name: result?.student?.name,
      studentFullName: result?.student?.fullName,
      studentEmail: result?.student?.email,
      percentage: `${result?.score?.percentage}%`,
      sectionBreakdown: result?.score.sectionBreakdown,
      hasSection: result?.score?.hasSection,
      totalPointsScored: result?.score?.totalPointsScored,
      totalPointsPosssible: result?.score?.totalPointsPossible,
      premises: result?.student?.premises?.map((pr: IPremiseItem) => pr.name) || '',
      level: result?.testLevel?.name,
    }));
    return {
      results: modifyData,
      totalCount: data?.total_count || 0,
    };
  }, [data]);

  const notFound = (!tableData.length && canReset) || !tableData.length;

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const fileDownloader = useCallback(
    ({ fetchedRows, fileType }: { fetchedRows: any[]; fileType: string }) => {
      const rows = fetchedRows.map((rowData) =>
        EXCEL_DATA_KEYS.map((key) => rowData[key as keyof {}] as string | number | boolean),
      );
      csvDownloader({
        fileName: 'Result-list',
        fileType,
        headers: EXCEL_DOWNLOAD_HEADERS as string[],
        rows: rows as (string | number)[][],
      });
    },
    [],
  );

  const excelDataModifier = useCallback(
    (rows: IResultsItem[]) =>
      rows?.map((result) => ({
        id: result?.id,
        date: format(parseISO(result.createdAt), 'dd-MM-yyyy') || '',
        test: result?.test?.name,
        testId: result?.test?.id,
        name: result?.student?.name,
        studentFullName: result?.student?.fullName,
        studentEmail: result?.student.email,
        percentage: `${result?.score?.percentage}%`,
        points: `${result?.score?.totalPointsScored}/${result?.score?.totalPointsPossible}`,
        premises: result?.student?.premises?.map((pr: IPremiseItem) => pr.name) || [],
        level: result.testLevel?.name,
      })) || [],
    [],
  );

  const downloadStudents = useCallback(
    async (fileType: string) => {
      try {
        setIsFileLoading(true);
        const {
          data: { data: downloadData },
        } = await axiosInstance(endpoints.staff.results.root, {
          params: canReset
            ? removeFalsyValuesFromObject({
                ...apiFiltersHandler(),
                ...tableFiltersHandler({ fetchAll: true }),
                search: debouncedSearchText,
              })
            : { sendAllRows: true },
        });

        if (downloadData?.data.length > 0) {
          fileDownloader({ fetchedRows: excelDataModifier(downloadData?.data || []), fileType });
        } else {
          enqueueSnackbar(t('common.no_data'), { variant: 'error' });
        }
        setIsFileLoading(false);
      } catch (error) {
        setIsFileLoading(false);
        enqueueSnackbar(t('common.failed_to_export_data'), { variant: 'error' });
      }
    },
    [
      apiFiltersHandler,
      canReset,
      debouncedSearchText,
      excelDataModifier,
      fileDownloader,
      t,
      tableFiltersHandler,
    ],
  );

  const fileDownloadHandler = useCallback(() => downloadStudents('csv'), [downloadStudents]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={t('common.results')}
        links={[{ name: t('common.dashboard'), href: '#' }, { name: t('common.results') }]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Card
        sx={{
          mt: { xs: 3, md: 5 },
        }}
      >
        <ResultsToolbar
          onFilters={handleFilters}
          filters={filters}
          fileDownloadHandler={fileDownloadHandler}
          isFileLoading={isFileLoading}
        />

        {canReset && (
          <ResultsTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            onResetFilters={handleResetFilters}
            //
            results={totalCount}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                // TODO:
                rowCount={20}
                numSelected={table.selected.length}
                onSort={table.onSort}
              />

              <TableBody>
                {tableData.length > 0 &&
                  tableData.map((row, index) => (
                    <ResultsTableRow key={`${row.id} ${index}`} row={row} headLabel={TABLE_HEAD} />
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={totalCount}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>
    </Container>
  );
}
