// import { paramCase } from 'src/utils/change-case';

// import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

// const MOCK_ID = _id[1];

// const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  // AUTH: '/auth',
  // AUTH_DEMO: '/auth-demo',
  // DASHBOARD: '/dashboard',
  STUDENT: '/student',
  STAFF: '/staff',
  STAFF_TEST: '/staff/test',
  STAFF_NOTIFICATIONS: '/staff/notifications',
  TEST: '/test',
  RESULTS: '/results',
};

// ----------------------------------------------------------------------

export const paths = {
  root: '/',
  // comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  // pricing: '/pricing',
  // payment: '/payment',
  courses: '/courses',
  premises: '/premises',
  aboutUs: '/about-us',
  jobs: '/jobs',
  franchising: '/franchising',
  privacyPolicy: '/privacy-policy',
  cookiePolicy: '/cookie-policy',
  cookieSettings: '/cookie-settings',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // product: {
  //   root: `/product`,
  //   checkout: `/product/checkout`,
  //   details: (id: string) => `/product/${id}`,
  //   demo: {
  //     details: `/product/${MOCK_ID}`,
  //   },
  // },
  // post: {
  //   root: `/post`,
  //   details: (title: string) => `/post/${paramCase(title)}`,
  //   demo: {
  //     details: `/post/${paramCase(MOCK_TITLE)}`,
  //   },
  // },
  login: '/login',
  signup: '/signup',
  completeLogin: '/complete-login',
  test: {
    root: `${ROOTS.TEST}`,
    answerTest: (id: string, isSharedTestLink?: boolean) =>
      `${ROOTS.TEST}/${id}${isSharedTestLink ? '?source=direct' : ''}`,
    retryTest: (id: string) => `${ROOTS.TEST}/${id}/retry`,
    testSubmission: (id: string, isSharedTestLink?: boolean) =>
      `${ROOTS.TEST}/${id}/submission${isSharedTestLink ? '?source=direct' : ''}`,
    testSubmissionAuthentication: (id: string) => `${ROOTS.TEST}/${id}/submission/authentication`,
  },
  results: {
    root: `${ROOTS.RESULTS}`,
    resultDetails: (id: string, isAfterTest?: string) => `${ROOTS.RESULTS}/${id}/${isAfterTest}`,
  },
  staff: {
    root: ROOTS.STAFF,
    results: `${ROOTS.STAFF}/results`,
    test: {
      root: `${ROOTS.STAFF_TEST}`,
      categories: {
        root: `${ROOTS.STAFF_TEST}/categories`,
        editView: (id: string) => `${ROOTS.STAFF_TEST}/categories/${id}`,
      },
      tests: {
        root: `${ROOTS.STAFF_TEST}/tests`,
        edit: (id: string) => `${ROOTS.STAFF_TEST}/tests/${id}/edit`,
      },
      questions: {
        root: `${ROOTS.STAFF_TEST}/questions`,
        new: `${ROOTS.STAFF_TEST}/questions/new`,
        edit: (id: string) => `${ROOTS.STAFF_TEST}/questions/${id}/edit`,
      },
    },
    students: `${ROOTS.STAFF}/students`,
    studentDetails: (id: string) => `${ROOTS.STAFF}/students/${id}`,
    premises: `${ROOTS.STAFF}/premises`,
    premiseEdit: (id: string) => `${ROOTS.STAFF}/premises/${id}`,
    collaborators: `${ROOTS.STAFF}/collaborators`,
    notifications: {
      root: `${ROOTS.STAFF_NOTIFICATIONS}`,
      email: `${ROOTS.STAFF_NOTIFICATIONS}/email`,
      getEmailTemplates: (role: string) => `${ROOTS.STAFF_NOTIFICATIONS}/email/${role}`,
      settings: `${ROOTS.STAFF_NOTIFICATIONS}/settings`,
    },
    account: `${ROOTS.STAFF}/account`,
  },
  student: {
    root: `${ROOTS.STUDENT}`,
    results: `${ROOTS.STUDENT}/results`,
    test: {
      root: `${ROOTS.STUDENT}${ROOTS.TEST}`,
      questions: (id: string) => `${ROOTS.STUDENT}${ROOTS.TEST}/${id}/questions`,
    },
    account: `${ROOTS.STUDENT}/account`,
  },

  // AUTH
  // auth: {
  //   root: ROOTS.AUTH,
  //   verify: `${ROOTS.AUTH}/verify`,
  // },
  // DASHBOARD
  // dashboard: {
  //   root: ROOTS.DASHBOARD,
  //   mail: `${ROOTS.DASHBOARD}/mail`,
  //   general: {
  //     app: `${ROOTS.DASHBOARD}/app`,
  //     ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
  //     analytics: `${ROOTS.DASHBOARD}/analytics`,
  //     banking: `${ROOTS.DASHBOARD}/banking`,
  //     booking: `${ROOTS.DASHBOARD}/booking`,
  //     file: `${ROOTS.DASHBOARD}/file`,
  //   },
  //   user: {
  //     root: `${ROOTS.DASHBOARD}/user`,
  //     new: `${ROOTS.DASHBOARD}/user/new`,
  //     list: `${ROOTS.DASHBOARD}/user/list`,
  //     cards: `${ROOTS.DASHBOARD}/user/cards`,
  //     profile: `${ROOTS.DASHBOARD}/user/profile`,
  //     account: `${ROOTS.DASHBOARD}/user/account`,
  //     edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
  //     demo: {
  //       edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
  //     },
  //   },
  //   product: {
  //     root: `${ROOTS.DASHBOARD}/product`,
  //     new: `${ROOTS.DASHBOARD}/product/new`,
  //     details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
  //     edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
  //     demo: {
  //       details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
  //       edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
  //     },
  //   },
  //   invoice: {
  //     root: `${ROOTS.DASHBOARD}/invoice`,
  //     new: `${ROOTS.DASHBOARD}/invoice/new`,
  //     details: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}`,
  //     edit: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
  //     demo: {
  //       details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
  //       edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
  //     },
  //   },
  //   post: {
  //     root: `${ROOTS.DASHBOARD}/post`,
  //     new: `${ROOTS.DASHBOARD}/post/new`,
  //     details: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
  //     edit: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
  //     demo: {
  //       details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
  //       edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
  //     },
  //   },
  //   order: {
  //     root: `${ROOTS.DASHBOARD}/order`,
  //     details: (id: string) => `${ROOTS.DASHBOARD}/order/${id}`,
  //     demo: {
  //       details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
  //     },
  //   },
  //   job: {
  //     root: `${ROOTS.DASHBOARD}/job`,
  //     new: `${ROOTS.DASHBOARD}/job/new`,
  //     details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
  //     edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
  //     demo: {
  //       details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
  //       edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
  //     },
  //   },
  //   tour: {
  //     root: `${ROOTS.DASHBOARD}/tour`,
  //     new: `${ROOTS.DASHBOARD}/tour/new`,
  //     details: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}`,
  //     edit: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
  //     demo: {
  //       details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
  //       edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
  //     },
  //   },
  // },
};
